.App {
  display: grid;
justify-items: center;
}

.appWrapper{
  background-image: url('images/background.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px;
  display: grid;
  gap: 10px;
  grid-template-rows:1fr 6fr ;
  min-height: 980px;
  width: 1300px;
 height: fit-content;
}
.wrapperTop{
  display: grid;
  grid-auto-flow: column;

}
.avatar{
  justify-self: left;
}
h1{
  height: fit-content;
  color: #f5edeb;
}
.myWorkContainer{
  justify-items: center;
  display: grid;
 grid-template-columns: 1fr 1fr 1fr;
   height: fit-content;

}
