.cardMyWork{
    display: grid;
    color: #040404;
    height: fit-content;
    background-color: rgba(245, 237, 235, 0.62);
}

 a{
    color: #0e05b5;
    text-decoration: none;
}
 a:hover{
     font-size: 15px;
 }

.title{

    padding-top: 10px;
}